import React from "react"
//import { Link } from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SectionBox from "../components/section-box"
import ParallaxSection from "../components/parallax-section"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import styled from "styled-components"

//SVG backgrounds
import bgSvg1 from "../images/bg home 01.svg"
import bgSvg2 from "../images/bg home 02.svg"
import bgSvg3 from "../images/bg home 03.svg"
import bgSvg4 from "../images/bg home 04.png"

//Ilustration inports
import ilustration1 from "../images/oblakoder-home-1.png"
import ilustration2 from "../images/oblakoder-home-2.png"
import ilustration3 from "../images/oblakoder-home-3.png"
import ilustration4 from "../images/oblakoder-home-4.png"
import ilustration5 from "../images/oblakoder-home-5.png"
import ilustration6 from "../images/oblakoder-home-6.png"

import Ilustration1 from "../components/ilustratino1"
import Ilustration2 from "../components/ilustratino2"
import Ilustration3 from "../components/ilustratino3"
import Ilustration4 from "../components/ilustratino4"
import Ilustration5 from "../components/ilustratino5"
import Ilustration6 from "../components/ilustratino6"

const GridStyled1 = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  padding: 3em 0;
  max-width: 80%;
  margin: 0 auto;
  height: 85vh;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  span {
    font-weight: 400;
  }
  h3 {
    color: #1a73e8;
  }
`

const GridStyled2 = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  padding: 3em 0;
  max-width: 80%;
  margin: 0 auto;
  height: 85vh;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  span {
    font-weight: 400;
  }
  h3 {
    color: #1a73e8;
  }
`

const ImageBox1 = styled.div`
  text-align: right;
  position: relative;
  .res-img {
    display: none;
    padding-top: 3em;
  }

  @media (max-width: 768px) {
    .res-img {
      display: block;
    }
    .png-img {
      display: none;
    }
  }
`

const ImageBox2 = styled.div`
  text-align: left;
  position: relative;
  .res-img {
    display: none;
    padding-top: 3em;
  }

  @media (max-width: 768px) {
    .res-img {
      display: block;
    }
    .png-img {
      display: none;
    }
  }
`

const SvgBg1 = styled.div`
  background-image: url(${bgSvg1});
  background-repeat: no-repeat;
  background-size: 100%;
`

const SvgBg2 = styled.div`
  background-image: url(${bgSvg2});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
`

const SvgBg3 = styled.div`
  background-image: url(${bgSvg3});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
`

const SvgBg4 = styled.div`
  background-image: url(${bgSvg4});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`

const ParallaxStyle = styled.div`
  text-align: center;
  padding: 10em 0;
  h2 {
    font-size: 3em;
    color: #fff;
    padding-bottom: 0.5em;
  }
  button {
    color: #fff;
    font-size: 1.2em;
    border: 2px solid #fff;
    background-color: transparent;
    padding: 0.6em 4.5em;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      background-color: #71717130;
      box-shadow: -1px 1px 9px 5px #4f079e;
    }
  }
  @media (max-width: 768px) {
    padding: 5em 0;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              Migrate to <span>AWS</span>
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                Cloud and automation expertise to painlessly migrate your
                applications and databases to AWS.
              </p>
            </SectionBox>
            <AniLink fade to="/migrate-to-aws">
              <Button>Read More</Button>
            </AniLink>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <Ilustration1 />
          </div>
          <div className="res-img">
            <img src={ilustration1} alt="Migrate to AWS" />
          </div>
        </ImageBox1>
      </GridStyled1>
      <GridStyled2>
        <ImageBox2>
          <div className="png-img">
            <Ilustration2 />
          </div>
          <div className="res-img">
            <img src={ilustration2} alt="AWS Security in the cloud" />
          </div>
        </ImageBox2>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>AWS</span>
              <br />
              Security in the cloud
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                The highest priority. The Security.
                <br />
                Raise security standards and treat response on security
                detection in your cloud.
              </p>
            </SectionBox>
            <AniLink fade to="/security-in-the-cloud">
              <Button>Read More</Button>
            </AniLink>
          </ScrollAnimation>
        </div>
      </GridStyled2>
    </SvgBg1>
    <SvgBg2>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>AWS</span>
              <br />
              Well-
              <br /> Architected
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                Learn, measure, and build using architectural best practices
              </p>
            </SectionBox>
            <AniLink fade to="/aws-well-architected">
              <Button>Read More</Button>
            </AniLink>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <Ilustration3 />
          </div>
          <div className="res-img">
            <img src={ilustration3} alt="AWS Well-Architected" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg2>
    <ParallaxSection>
      <ParallaxStyle>
        <ScrollAnimation animateIn="fadeIn" duration={1.8}>
          <h2>Reach for the Cloud</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" duration={1}>
          <AniLink fade to="/contact">
            <button>Reach Out</button>
          </AniLink>
        </ScrollAnimation>
      </ParallaxStyle>
    </ParallaxSection>
    <SvgBg3>
      <GridStyled2>
        <ImageBox1>
          <div className="png-img">
            <Ilustration5 />
          </div>
          <div className="res-img">
            <img src={ilustration5} alt="Serverless AWS" />
          </div>
        </ImageBox1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              Serverless <span>AWS</span>
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>Build and run applications without thinking about servers</p>
            </SectionBox>
            <AniLink fade to="/serverless-aws">
              <Button>Read More</Button>
            </AniLink>
          </ScrollAnimation>
        </div>
      </GridStyled2>
    </SvgBg3>
    <SvgBg4>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>AWS</span>
              <br />
              for Developers
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>We can help you to set your infrastructure as a code.</p>
            </SectionBox>
            <AniLink fade to="/aws-for-developers">
              <Button>Read More</Button>
            </AniLink>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <Ilustration4 />
          </div>
          <div className="res-img">
            <img src={ilustration4} alt="Serverless AWS" />
          </div>
        </ImageBox1>
      </GridStyled1>
      <GridStyled2>
        <ImageBox2>
          <div className="png-img">
            <Ilustration6 />
          </div>
          <div className="res-img">
            <img src={ilustration6} alt="AWS Contact" />
          </div>
        </ImageBox2>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>Contact </span>
              Us
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p></p>
            </SectionBox>
            <AniLink fade to="/contact">
              <Button>Get In Touch</Button>
            </AniLink>
          </ScrollAnimation>
        </div>
      </GridStyled2>
    </SvgBg4>
  </Layout>
)

export default IndexPage
