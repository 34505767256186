import React from "react"
import { useSpring, animated } from "react-spring"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import { Parallax } from "react-scroll-parallax"

//Ilustration inports
import ilustration1_1 from "../images/homePng/slika6/s6-bg.png"
import ilustration1_2 from "../images/homePng/slika6/s6-objekt1.png"
import ilustration1_3 from "../images/homePng/slika6/s6-icons.png"
import ilustration1_4 from "../images/homePng/slika6/s6-objekt2.png"

import styled from "styled-components"

const AnimationBox = styled.div`
  position: relative;
  top: -30vh;
  right: 0;
  width: 100%;
  height: 100%;
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 40vw;
  }
`

const AnimateLoop1 = styled.div`
  transform: translatey(0px);
  animation: float 14s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translatey(5px, 5px);
    }
    50% {
      transform: translatey(-1px, -1px);
    }
    100% {
      transform: translatey(5px, 5px);
    }
  }
`

const AnimateLoop2 = styled.div`
  transform: translatey(0px);
  animation: float 10s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translatey(5px);
    }
    50% {
      transform: translatey(-15px);
    }
    100% {
      transform: translatey(5px);
    }
  }
`

const AnimateLoop3 = styled.div`
  transform: translatey(0px);
  animation: float 15s ease-in-out infinite;
  z-index: 999;

  @keyframes float {
    0% {
      transform: translatey(5px);
    }
    50% {
      transform: translatey(-15px);
    }
    100% {
      transform: translatey(5px);
    }
  }
`

const AnimateLoop4 = styled.div`
  transform: translatey(0px);
  animation: float 12s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translatey(5px);
    }
    50% {
      transform: translatey(-15px);
    }
    100% {
      transform: translatey(5px);
    }
  }
`

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 25}px,${y / 25}px,0)`
const trans2 = (x, y) => `translate3d(${x / 50}px,${y / 55}px,0)`
const trans3 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`
const trans4 = (x, y) => `translate3d(${x / 25}px,${y / 25}px,0)`

const Ilustration1 = () => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 90, tension: 1500, friction: 800 },
  }))

  return (
    <AnimationBox
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <AnimateLoop1>
        <animated.div
          className="figure"
          style={{ transform: props.xy.interpolate(trans1) }}
        >
          <Parallax y={[30, -30]} tagOuter="figure">
            <img src={ilustration1_1} />
          </Parallax>
        </animated.div>
      </AnimateLoop1>
      <AnimateLoop2>
        <animated.div style={{ transform: props.xy.interpolate(trans3) }}>
          <Parallax y={[30, -30]} tagOuter="figure">
            <img src={ilustration1_2} />
          </Parallax>
        </animated.div>
      </AnimateLoop2>
      <AnimateLoop3>
        <animated.div style={{ transform: props.xy.interpolate(trans2) }}>
          <Parallax y={[20, -20]} tagOuter="figure">
            <img src={ilustration1_3} />
          </Parallax>
        </animated.div>
      </AnimateLoop3>
      <AnimateLoop4>
        <animated.div style={{ transform: props.xy.interpolate(trans4) }}>
          <Parallax y={[40, -40]} tagOuter="figure">
            <img src={ilustration1_4} />
          </Parallax>
        </animated.div>
      </AnimateLoop4>
    </AnimationBox>
  )
}

export default Ilustration1
