import React from "react"

import styled from "styled-components"

import buttonIcon from "../images/button-icon.png"

const ButtonS = styled.button`
  text-align: center;
  position: relative;
  padding: 0.65em 5em 0.65em 3.7em;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  background: linear-gradient(90deg, #aa3ff6, #7b4de1, #3361c2, #0d6bb1);
  border: none;
  outline: none;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: -1px 4px 12px 0px #7b4de187;
  }
  &:hover img {
    right: 12%;
  }
  img {
    position: absolute;
    top: 50%;
    right: 16%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
  }
`

const Button = ({ children }) => (
  <ButtonS>
    {children}
    <span>
      <img src={buttonIcon} />
    </span>
  </ButtonS>
)

export default Button
