import React from "react"
import { Parallax, Background } from "react-parallax"

const ParallaxSection = ({ children }) => (
  <div>
    <Parallax
      blur={0}
      bgImage={require("../images/oblakoder-paralexa-home.jpg")}
      bgImageAlt="ubstract aws"
      strength={200}
    >
      {children}
    </Parallax>
  </div>
)

export default ParallaxSection
